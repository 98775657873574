<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  /* cursor: pointer; */
  background-color: unset;
}
th {
  border-bottom: 1px solid #343a40;
}
td.actions:hover {
  opacity: 1 !important;
}
.btn:disabled {
  pointer-events: none;
}
@media screen and (max-width: 767px){
  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
      white-space: unset;
  }
}
</style>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h4 v-if="pasteDownloads.length>0" class="text-primary my-3">
          Origen: {{ nameNemrec }}
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          v-if="pasteDownloads.length>0 && !uploadDone"
          class="btn btn-info btn-sm float-right ml-2"
          :class="{disabled: loading}"
          :disabled="loading"
          @click.prevent="addQueue"
        >
          <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ loading ? "Agregando descargas...":"Descarga Masiva" }}
        </button>

        <button
          v-if="pasteDownloads.length>0"
          class="btn btn-default btn-sm float-left"
          :disabled="loading"
          @click.prevent="clearQueue"
        >
          Limpiar lista
        </button>

        <button
          v-if="pasteDownloads.length>0"
          class="btn btn-default btn-sm float-right"
          :disabled="loading"
          @click.prevent="downloadMsv"
        >
          Descarga MSV
        </button>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col">
        <table v-if="pasteDownloads.length>0" class="table table-hover inshadow col">
          <tbody>
            <tr v-for="it in pasteDownloads" :key="it.hash">
              <td class="">
                <dl class="mb-0">
                  <dd class="text-info">
                    {{ it.datetime }} <br>
                    {{ it.duration }}
                  </dd>
                  <dd>
                    {{ it.place }} {{ it.idbloq }}
                  </dd>
                  <dd class="text-primary">
                    {{ it.product }} <br>
                    {{ it.version }}
                  </dd>
                  <dd>
                    {{ it.filename }}
                  </dd>
                </dl>
              </td>
              <td>
                <Icon
                  v-show="it.isodate.length > 0 && it.durseconds > 0"
                  class="text-success"
                  icon="mdi:success"
                  inline="true"
                  width="1.5em"
                  height="1.5em"
                />
                <Icon v-show="!(it.isodate.length > 0 && it.durseconds > 0)" class="text-danger" icon="la:times" :inline="true" width="1.5em" height="1.5em" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          v-if="pasteDownloads.length>0 && !uploadDone"
          class="btn btn-info btn-sm float-right ml-2"
          :class="{disabled: loading}"
          :disabled="loading"
          @click.prevent="addQueue"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
          ></span>
          {{ loading ? "Agregando descargas...":"Descarga Masiva" }}
        </button>

        <button
          v-if="pasteDownloads.length>0"
          class="btn btn-default btn-sm float-left"
          :disabled="loading"
          @click.prevent="clearQueue"
        >
          Limpiar lista
        </button>

        <button
          v-if="pasteDownloads.length>0"
          class="btn btn-default btn-sm float-right"
          :disabled="loading"
          @click.prevent="downloadMsv"
        >
          Descarga MSV
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  format, parse, parseISO, isValid, isAfter, differenceInSeconds
} from "date-fns";
import { es } from "date-fns/locale";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Devices.service";

export default {
  name: "EnqueueTable",
  components: {
    Icon
  },
  props: {
    tuner:{
      type: String,
      default: "1"
    },
    rawText:{
      type: String,
      default: ""
    },
    nemrecs: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default() {
        return {
          _id: null,
          hwuid: "",
          name: "",
          tinchost:"",
          tincip: ""
        };
      }
    }
  },
  data() {
    return {
      pasteDownloads: [],
      loading: false,
      uploadDone: false
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    nameNemrec() {
      const tun = parseInt(this.tuner, 10);
      if (Number.isNaN(tun)) {
        return `Tuner ${this.tuner}`;
      }
      return this.nemrecs[tun - 1] ?? `Tuner ${this.tuner}`;
    },
    tincIp() {
      const { tincip = "" } = this.item ?? {};
      return tincip;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    rawText(val, old) {
      if (val.length > 0) {
        this.onPaste(val);
      }
    }
  },
  methods: {
    onPaste(paste) {
      // console.log(paste);
      let lines = paste.split("\n");
      lines = lines.map(l => this.parseLine(l))
        .filter(k => k && k.isodate.length > 0 && k.durseconds > 0);

      if (lines.length === 0) {
        this.showAlert({
          title: "Descarga masiva",
          msg: "Información inválida al pegar",
          icon: "error"
        });
        this.$emit("queueSizeChanged", 0);
        return;
      }
      this.pasteDownloads = lines.map(it => ({ ...it, filename: this.generateFilename(it) }));
      this.$emit("queueSizeChanged", this.pasteDownloads.length);
    },
    parseLine(line) {
      const cells = line.split("\t");
      if (cells.length < 6) {
        return null;
      }

      const [
        datetime = "",
        duration = 0,
        place = "",
        idbloq = "",
        product = "",
        version = ""
      ] = cells;

      return {
        isodate: this.parseDatetime(datetime),
        durseconds: this.parseDuration(duration),
        datetime,
        duration,
        place,
        idbloq,
        product,
        version
      };
    },
    parseDatetime(datetime) {
      try {
        const dt = parse(datetime, "dd/MM/yyyy HH:mm:ss", new Date(), { locale: es });
        return (isValid(dt) && isAfter(dt, new Date(2022, 0, 1))) ? dt.toISOString() : "";
      }
      catch (e) {
        return "";
      }
    },
    parseDuration(duration) {
      const d = parseInt(duration, 10);
      if (!duration.includes(":") && !Number.isNaN(d) && d > 0) {
        return d;
      }
      try {
        const end = parse(duration, "HH:mm:ss", new Date(2000, 0, 1), { locale: es });
        return Math.abs(differenceInSeconds(new Date(2000, 0, 1), end));
      }
      catch (e) {
        return 0;
      }
    },
    addQueue() {
      this.loading = true;
      this.uploadDone = false;
      if (!this.tincIp) {
        this.showAlert({ title:"No se pudo encolar elementos", msg:"Es necesario tincIp", icon: "error" });
        return;
      }
      // Antes de enviar agregarle al json el tuner, filename y hash
      // Se cambia datetime por la isodate y la duracion por segundos
      const list = this.pasteDownloads.map(it => {
        const {
          isodate,
          durseconds,
          place,
          idbloq,
          product,
          version
        } = it;

        const filename = this.generateFilename({
          isodate, place, idbloq, product, version, tuner: this.tuner
        });

        return {
          datetime: isodate,
          duration: durseconds,
          tuner: this.tuner,
          place,
          idbloq,
          product,
          version,
          filename
        };
      });
      console.log(list);
      // setTimeout(() => {
      //   this.loading = false;
      //   this.uploadDone = true;
      // }, 2000);
      ServiceApi.addDownloadList(this.tincIp, list)
        .then(res => {
          console.log("ADD RES:", res.data);
          this.loading = false;
          this.uploadDone = true;
          this.$emit("enqueueUpdated");
        })
        .catch(err => {
          this.loading = false;
          const { response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = err } = dataResponse;
          this.showAlert({
            title: "Descarga masiva",
            msg: `${errStr}, CODE: ${status}`,
            icon: "error"
          });
        });
    },
    generateFilename(row) {
      const {
        isodate,
        place,
        idbloq,
        product,
        version,
        // tuner = this.tuner
      } = row ?? {};

      const dt = parseISO(isodate);
      const dateFormat = format(dt, "yyyyMMdd-HHmmss", { locale: es });

      const segName = [];
      // segName.push(`T${tuner}`);
      segName.push(dateFormat);
      segName.push(this.sanitizeStr(place));
      segName.push(this.sanitizeStr(idbloq));
      segName.push(this.sanitizeStr(product));
      segName.push(this.sanitizeStr(version));
      const filename = `${segName.join("_")}.mp4`;
      return filename;
    },
    sanitizeStr(str) {
      if (typeof str !== "string") {
        return "";
      }

      // eslint-disable-next-line quotes
      const validChars = ` abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_`;
      let strSanitized = str.split("")
        .filter((char) => validChars.indexOf(char) > -1)
        .join("")
        .replace(/ +(?= )/g, "") // Quita los dobles espacios
        .trim()
        .replaceAll(" ", "-");

      strSanitized = strSanitized.substring(0, 20);
      return strSanitized;
    },
    clearQueue() {
      this.pasteDownloads = [];
      this.uploadDone = false;
      this.$emit("queueSizeChanged", this.pasteDownloads.length);
      this.$emit("clearPaste");
    },
    downloadMsv() {
      const list = this.pasteDownloads.map(it => {
        const {
          isodate,
          durseconds,
          place,
          idbloq,
          product,
          version
        } = it;

        const filename = this.generateFilename({
          isodate, place, idbloq, product, version, tuner: this.tuner
        });

        return {
          datetime: isodate,
          duration: durseconds,
          tuner: this.tuner,
          place,
          idbloq,
          product,
          version,
          filename
        };
      });

      const bloburl = (window.URL || window.webkitURL).createObjectURL( new Blob([JSON.stringify(list, null, 2)], { type: "application/json" }) );
      const a = document.createElement("a");
      a.href = bloburl;
      a.download = "descarga-masiva.msv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    showAlert(msg) {
      const {
        title = "", msg:html = "", icon = "", timer = 4000
      } = msg ?? {};
      this.$swal({
        title,
        html,
        icon,
        timer,
        position: "bottom-end",
        showConfirmButton: false,
        showCancelButton: false,
        timerProgressBar: true,
        toast: true
      });
    }
  },
  mounted() {
  }
};
</script>
